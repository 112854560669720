<template>
  <div>
    <scheme-item-aside
      v-if="aside === 'scheme'"
      v-model="aside"
      :resource="resource"
      @changed="loadSchemeItems(); aside = false"
      @aside="handleAside"
    ></scheme-item-aside>

    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-5">
        <v-select
          v-model="schemeIdFilter"
          label="Scheme"
          :items="schemeOptions"
          item-text="title"
          item-value="value"
          outlined
          hide-details
        ></v-select>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="mr-2"
          @click="aside = 'scheme'; resource = null;"
        >
          Create +
        </v-btn>
      </v-card-text>
      <!-- table -->
      <v-data-table
        v-model="selectedTableData"
        :headers="tableColumns"
        :items="listTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.scheme.name`]="{item}">
          <span>{{ t(item.scheme.name) }} ({{ item.scheme.item_count }} items)</span>
        </template>

        <template #[`item.value`]="{item}">
          <span>- {{ item.value }} {{ item.value_type === 'percentage' ? '%' : baseCurrency.long_symbol }}</span>
        </template>

        <template #[`item.discountable`]="{item}">
          <span>
            {{ item.discountable.key }}
          </span>
        </template>

        <template #[`item.discountable_type`]="{item}">
          <span>
            {{ getName(item.discountable_type) }}
          </span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="resource = item; aside = 'scheme'"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="confirmTrashSchemeItem(item).then(loadSchemeItems)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { useCurrency } from '@/composables'
import comStore from '@/modules/common/store'
import { t } from '@/plugins/i18n'
import router from '@/router'
import { dateTime, price } from '@core/utils/filter'
import {
mdiDotsVertical, mdiPencil, mdiPlus, mdiTrashCan,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { onMounted } from 'vue-demi'

// composition function
import SchemeItemAside from '../discount-scheme-resource/SchemeItemAside.vue'
import useSchemeItemList from './useSchemeItemList'

export default {

  components: { SchemeItemAside },

  filters: { price, dateTime },

  setup() {
    const aside = ref('')
    const resource = ref('')

    const {
      listTable, searchQuery, schemeIdFilter, tableColumns, options, totalListTable, loading, selectedTableData, loadSchemeItems, confirmTrashSchemeItem,
    } = useSchemeItemList()

    onMounted(() => {
      schemeIdFilter.value = router.currentRoute.query.scheme_id
      loadSchemeItems()
    })

    const getName = className => className.split('\\')[className.split('\\').length - 1]

    const handleAside = bol => {
      if (!bol) {
        aside.value = false
        resource.value = false
        loadSchemeItems()
      }
    }

    const schemeOptions = computed(() => comStore.state.discountSchemes.map(scheme => ({
      title: t(scheme.name),
      value: scheme.id,
    })))

    const { baseCurrency } = useCurrency()

    return {
      tableColumns,
      searchQuery,
      schemeIdFilter,
      options,
      totalListTable,
      listTable,
      loading,
      selectedTableData,

      aside,
      resource,

      t,
      getName,

      loadSchemeItems,
      confirmTrashSchemeItem,
      handleAside,

      schemeOptions,
      baseCurrency,

      icons: {
        mdiDotsVertical,
        mdiPencil,
        mdiTrashCan,
        mdiPlus,
      },
    }
  },
}
</script>

    <style lang="scss" scoped>
    .invoice-list-search {
      max-width: 10.625rem;
    }
    .invoice-list-status {
      max-width: 11.3rem;
    }
    </style>
