import { destroySchemeItem, fetchSchemeItems } from '@/api/order'
import { useNotifyErrors, useTableOptions } from '@/composables'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useSchemeItemList() {
  const listTable = ref([])

  // Table Handlers
  const tableColumns = [
    {
      text: '#ID',
      align: 'start',
      value: 'id',
    },
    { text: 'Scheme Name', value: 'scheme.name', sortable: false },
    { text: 'Deduction', value: 'value', sortable: true },
    { text: 'Item Name', value: 'discountable', sortable: false },
    { text: 'Type', value: 'discountable_type', sortable: true },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const schemeIdFilter = ref(cached[2] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const selectedTableData = ref([])

  const loadSchemeItems = () =>
    fetchSchemeItems(
      useTableOptions(options.value, {
        search: searchQuery.value,
        schemeId: schemeIdFilter.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        listTable.value = records
        totalListTable.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const trashSchemeItem = id => destroySchemeItem(id).then(loadSchemeItems).catch(useNotifyErrors)

  const confirmTrashSchemeItem = schemeItem =>
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [
        {
          title: 'Confirm Action',
          content: `Confirm Trash Item: <b>${schemeItem.discountable.key}</b> ?`,
          resolve: () => trashSchemeItem(schemeItem.id).then(resolve),
          resolveText: 'Confirm',
          reject: () => reject(),
          rejectText: 'No',
        },
      ])
    })

  const cachable = [options, searchQuery, schemeIdFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      loadSchemeItems()
      cache()
    }, 300),
  )

  return {
    tableColumns,
    searchQuery,
    schemeIdFilter,
    options,
    listTable,
    totalListTable,
    loading,
    selectedTableData,
    loadSchemeItems,
    confirmTrashSchemeItem,
  }
}
