<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 680 : '100%'"
    class="pl-2 pr-2 pt-2 pb-2"
    app
    @input="(val) => $emit('aside', val)"
  >
    <scheme-item-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      :cols="12"
      @submit="handleSubmit"
    >
    </scheme-item-form>
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeSchemeItem, updateSchemeItem } from '@api/order'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import SchemeItemForm from './SchemeItemForm.vue'

export default {
  components: { SchemeItemForm },

  model: {
    prop: 'active',
    event: 'aside',
  },

  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const data = { ...form }

      const request = props.resource ? updateSchemeItem(props.resource.id, data) : storeSchemeItem(data, 'category')
      const discountScheme = await request
        .catch(err => {
          errors.value = useExtractErrors(err)
          loading.value = false
        })

      if (!discountScheme) return

      // let sync-ing done after discountScheme success.
      setTimeout(() => {
        emit(props.resource ? 'updated' : 'created', discountScheme)
        emit('changed', discountScheme)
        loading.value = false
      }, 1000)
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
